<template>
	<div class="page">
		<h3>Your Subscription</h3>

        <h4>Benefits</h4>

        <ul>
            <li>Share Codes</li>
            <li>No Data Limit</li>
            <li>Full Log History</li>
        </ul>

        <h4>Options</h4>

        <div class="menu">
            <div class="menu-item" v-for="(option,i) in options" :key="i">
                <div class="menu-title">{{option.name}}</div>
                <div class="menu-action">{{option.amountText}}</div>
            </div>
        </div>

		<div class="action-bar-container">
			<div class="action-bar-row">
				<router-link class="btn btn-action" to="/user"
					><i class="fal fa-fw fa-arrow-left"></i
				></router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Subscription",
    data: () => ({
        options: []
    }),
	computed: {
		...mapGetters({
            user: "user/data",
            optionsRaw: "subscription/options"
		}),
	},
	methods: {
		...mapActions({
            getOptions: "subscription/getOptions"
        }),
    },
    async created() {
        await this.getOptions();
        this.options = this.optionsRaw.map(option => {
            let name = "";

            if (option.recurring) {
                name = `Every ${option.recurring.intervalCount} ${option.recurring.interval}(s)`;
            }

            return {
                name,
                amount: option.unitAmount,
                amountText: "£" + (option.unitAmount / 100.0).toFixed(2)
            };
        });
        this.options.sort((a,b) => a.amount > b.amount ? 1 : -1);
    }
};
</script>
